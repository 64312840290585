<template>
  <div>
    <div class="main-page-content">
      <el-row class="table-header">
        <el-col :span="20">
          <el-form :inline="true" size="mini">
            <el-form-item label-width="">
              <el-checkbox v-model="searchCondition.showHidden">显示已隐藏</el-checkbox>
            </el-form-item>
            <el-form-item label="">
              <el-date-picker
                  v-model="searchCondition.ym"
                  type="month"
                  value-format="yyyy/MM"
                  format="yyyy/MM"
                  placeholder="选择月份" style="width: 120px" @change="changeMonth">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="">
              <artist-search-custom :type="``" @handleSelect="selectArtist" :show-first-group="true"  style="width: 100%"/>
            </el-form-item>
          </el-form>
        </el-col>

        <el-col :span="4" style="text-align: right;">
          <el-link class="default-log-link" type="primary" @click="showLog"
                   v-if="userPermissions.indexOf('schedule_log') !== -1">
            日志
            <i class="el-icon-document"></i></el-link>

          <export-link ref="refExportLink" :can-export="userPermissions.indexOf('schedule_export') !== -1"
                       @export="exportData"/>
          <ImageShare :share-content-id="`shareContent`" :btn-title="`分享档期`" :dialog-title="`档期分享图`"/>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <schedule-table id="shareContent" ref="referee" :search-condition="searchCondition"/>
        </el-col>
      </el-row>
    </div>

    <!--    日志-->
    <ScheduleLog :visible="logSetting.visible" @close="closeLog" :search-condition="searchCondition"
                 v-if="userPermissions.indexOf('schedule_log') !== -1"/>
  </div>
</template>

<script>
import ApeTable from "@/components/ApeTable";
import ApeDrawer from "@/components/ApeDrawer";
import ArtistSearch from "@/components/artist/ArtistSearch";
import ExportLink from "@/components/export/ExportLink";
import ScheduleTable from "@/pages/artist/schedule/ScheduleTable";
import ScheduleLog from "@/pages/artist/schedule/ScheduleLog";
import ArtistSearchCustom from "@/components/artist/ArtistSearchCustom";
import { mapGetters } from "vuex";

export default {
  name: "List",
  components: {
    ApeTable,
    ApeDrawer,
    ArtistSearch,
    ArtistSearchCustom,
    ExportLink,
    ScheduleLog,
    ScheduleTable
  },
  computed: {
    ...mapGetters(['userPermissions', 'buttonType'])
  },
  props: {},
  data() {
    return {
      searchCondition: {
        ym: '',
        year: null,
        month: null,
        nickname: null,
        artist_id: null,
        platform: { name: null, code: null },
        showHidden: false
      },
      artists: [],
      fileTitle: '',
      logSetting: {
        visible: false,
      },
    }
  },
  methods: {
    defaultTime() {
      const end = new Date();
      //年份选择：默认当月
      this.searchCondition.year = end.getFullYear()
      this.searchCondition.month = end.getMonth() + 1
      this.searchCondition.ym = end.getFullYear() + '/' + (end.getMonth() + 1)
    },
    changeMonth() {
      let ymArr = this.searchCondition.ym.split('/')
      this.searchCondition.year = parseInt(ymArr[0])
      this.searchCondition.month = parseInt(ymArr[1])
    },
    async selectArtist(selectInfo) {
      this.searchCondition.artist_id = selectInfo.artist_id;
      this.searchCondition.nickname = selectInfo.nickname;
      this.searchCondition.group_id = selectInfo.group_id;
      this.searchCondition.group_name = selectInfo.group_name;
    },

    // 处理搜索条件
    handleSearchCondition() {
      let condition = {}

      if (this.searchCondition.year) {
        condition.year = this.searchCondition.year
      }
      if (this.searchCondition.month) {
        condition.month = this.searchCondition.month
      }
      if (this.searchCondition.artist_id) {
        condition.artist_id = this.searchCondition.artist_id
      }

      return condition
    },

    //导出表格
    async exportData() {
      try {
        let searchCondition = this.handleSearchCondition()
        let response = await this.$api.exportArtistSchedules(searchCondition)
        let name = `档期日程-${ this.searchCondition.year }年${ this.searchCondition.month }月-${ this.searchCondition.nickname }`

        this.$refs.refExportLink.exportSaveXlsx(response, name)
      } catch (e) {
        this.$message.warning('导出异常，请联系管理员')
        // alert(JSON.stringify(e))
      }
    },
    showLog() {
      this.logSetting.visible = true
    },
    closeLog() {
      this.logSetting.visible = false
    },

  },
  mounted() {

  },
  created() {
    this.defaultTime()
  }
}
</script>

<style scoped>
.op-link {
  margin-top: 20px;
  margin-right: 20px;
  margin-bottom: 1px;
  font-size: 16px;
  font-weight: 600;
}
</style>
