<template>
  <el-dialog v-bind="$attrs" v-on="$listeners" @opened="onOpened" @close="onClose" title="操作日志" width="1100px">
    <ApeTable ref="apeTable" :data="dataList" :columns="columns" :loading="loadingStatus" :pagingData="pagingData"
              @switchPaging="switchPaging" highlight-current-row>
    </ApeTable>
  </el-dialog>
</template>

<script>
import ApeTable from "@/components/ApeTable";

export default {
  name: "ScheduleLog",
  inheritAttrs: false,
  components: {
    ApeTable
  },
  props: {
    searchCondition: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      loadingStatus: false,
      dataList: [],
      columns: [
        {
          title: '日志内容',
          value: 'msg',
          width: 300,
          header_align: 'center',
          align: 'left'
        },
        {
          title: '操作时间',
          value: 'created_at',
          width: 120,
          header_align: 'center',
          align: 'center'
        },
      ],
      // 分页信息
      pagingData: {
        is_show: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        offset: 0,
      },
    }
  },
  computed: {},
  watch: {},
  created() {
  },
  mounted() {

  },
  methods: {
    // 切换页码操作
    async switchPaging() {
      await this.initList()
    },
    async initList(type) {
      this.loadingStatus = true
      let pagingInfo = this.$refs['apeTable'].getPagingInfo(type)
      let searchCondition = this.handleSearchCondition()
      // 合并
      Object.assign(searchCondition, pagingInfo)
      let { list, pages } = await this.$api.getArtistScheduleLog(searchCondition)
      this.$nextTick(() => {
        this.dataList = list
      })
      this.pagingData.total = pages.total

      this.pagingData.offset = pages.offset
      this.loadingStatus = false
    },
    handleSearchCondition() {
      let condition = {}
      this.conditionStr = '';

      if (this.searchCondition.group_id) {
        condition.group_id = this.searchCondition.group_id
      }
      if (this.searchCondition.artist_id) {
        condition.artist_id = this.searchCondition.artist_id
      }

      return condition
    },
    onOpened() {
      this.switchPaging()
    },
    onClose() {
      this.dataList = []
      // this.$refs['elForm'].resetFields()
    },
  }
}

</script>

<style scoped>

</style>
