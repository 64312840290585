<template>
  <div>
    <el-dialog v-bind="$attrs" v-on="$listeners" @open="onOpen" @close="onClose" :title="dialogTitle">

      <el-row>
        <el-col :span="20" :offset="2">
          <!--          <div>{{ drag ? '拖拽中' : '拖拽停止' }}</div>-->
          <!--使用draggable组件-->
          <draggable v-model="dataList" chosenClass="chosen" forceFallback="true" group="people" animation="1000"
                     @start="onStart" @end="onEnd">
            <transition-group>
              <div class="item" v-for="element in dataList" :key="element.code">{{ element.name }}</div>
            </transition-group>
          </draggable>
        </el-col>
      </el-row>
      <div slot="footer">
        <el-row>
          <el-col :span="24">
            <el-button @click="close">取消</el-button>
            <el-button type="primary" @click="handleConfirm" :disabled="loadingCommit" :loading="loadingCommit"
                       v-if="canEdit">
              保存
            </el-button>
            <el-button type="default" :disabled="true" v-else>
              保存(无权限)
            </el-button>
          </el-col>
        </el-row>
      </div>
    </el-dialog>
  </div>
</template>

<script>
//导入draggable组件
import draggable from 'vuedraggable'

export default {
  name: "PlatformOrder",
  //注册draggable组件
  components: {
    draggable,
  },
  computed: {
    dialogTitle() {
      return `档期平台排序：${ this.searchCondition.nickname }`
    }
  },
  props: {
    searchCondition: {
      type: Object,
      default() {
        return {
          artist_id: null,
          nickname: null,
        }
      }
    },
    canEdit: {
      type: Boolean,
      default() {
        return false;
      }
    }
  },
  data() {
    return {
      drag: false,
      dataList: [],
      loadingCommit: false,
    }
  },
  methods: {
    onOpen() {
      this.loadingCommit = false
      // this.getAInfo()
      this.getSort()
    },
    onClose() {
      // this.$refs['elForm'].resetFields()
      this.$emit('refreshData')
    },
    close() {
      this.$emit('update:visible', false)
    },
    //开始拖拽事件
    onStart() {
      this.drag = true;
    },
    //拖拽结束事件
    onEnd() {
      this.drag = false;
      // console.log('dataList', this.dataList)
    },
    async handleConfirm() {
      this.loadingCommit = true
      let params = {
        artist_id: this.searchCondition.artist_id,
        nickname: this.searchCondition.nickname,
        sort_map: this.dataList.map((item) => {
          return item.code
        })
      }
      await this.$api.saveArtistScheduleSort(params)
      this.close()
    },
    async getSort() {
      let { info, list } = await this.$api.getArtistScheduleSort({ artist_id: this.searchCondition.artist_id })
      this.dataList = list
      // this.dataList = list.map((item) => {
      //   return { code: item.code, name: item.name }
      // })

      return info;
    }
  }
}
</script>

<style scoped>
.item {
  padding: 6px;
  background-color: #fdfdfd;
  border: solid 1px #eee;
  margin-bottom: 10px;
  cursor: move;
  text-align: center;
}

.item:hover {
  background-color: #f1f1f1;
  cursor: move;
}

.chosen {
  /*border: solid 2px #3089dc !important;*/
  border: solid 2px #ff3176 !important;
}
</style>
