<template>
  <div>
    <el-dialog v-bind="$attrs" v-on="$listeners" @open="onOpen" @close="onClose" :title="dialogTitle">
      <el-form ref="elForm" :model="formData" :rules="rules" label-width="100px">
        <el-form-item label="排期内容：">
          <el-input v-model="formData.plan_content"
                    type="textarea"
                    :autosize="{ minRows: 6, maxRows: 10}"
                    placeholder="请输入档期内容"
                    clearable
                    :style="{width: '100%'}" maxlength="500" :disabled="formData.source_id" show-word-limit>

          </el-input>
        </el-form-item>
        <el-form-item label="排期类型：">
          <el-radio-group v-model="formData.plan_type">
            <el-radio :label="item.plan_type" :value="item.plan_type" v-for="(item,key) in planTypeMap" :key="key"
                      @change="changePlanType(item)" :disabled="item.disabled||formData.source_id">
              {{ item.label }}
            </el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="背景色：">
          <el-row>
            <el-col :span="4">
              <el-color-picker
                  v-model="color"
                  show-alpha
                  :predefine="predefineColors" :disabled="formData.source_id">
              </el-color-picker>
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item>
          <span style="color: #909399;font-size: 14px">
            <i class="el-icon-info" style="color: #F56C6C;font-size: 18px;margin-right: 10px"></i>
            <span>商务内容请到</span>
            <el-link type="primary" :underline="true" href="/s_menus/business_manage"
                     target="_blank">商务管理</el-link><span>
            录入!!!</span>
          </span>
        </el-form-item>
      </el-form>
      <el-dialog
          width="30%"
          title="延期操作"
          :visible.sync="innerVisible"
          append-to-body>
        <el-form :inline="true" label-width="100px">
          <el-form-item label="延期至：">
            <el-date-picker
                v-model="delayFormData.new_date"
                type="date"
                format="yyyy年MM月dd日"
                value-format="yyyy-MM-dd"
                placeholder="选择上线日期" clearable>
            </el-date-picker>
          </el-form-item>
        </el-form>
        <div slot="footer">
          <el-button type="primary" @click="saveDelay">确定延期</el-button>
        </div>
      </el-dialog>
      <div slot="footer">
        <el-row>
          <el-col :span="24">
            <div style="display: inline;">
              <el-button type="warning" @click="delay" v-if="currentId&&!formData.source_id" :disabled="!canEdit">
                延期<i class="el-icon-d-arrow-right"></i>
              </el-button>
              <el-button type="danger" plain @click="delBtn" v-if="currentId" :disabled="!canEdit">
                删除<i class="el-icon-delete"></i>
              </el-button>
              <el-button @click="close">关闭</el-button>
            </div>
            <div v-if="!formData.source_id" style="display: inline;margin-left: 10px">
              <el-button type="primary" @click="handleConfirm" :disabled="loadingCommit" :loading="loadingCommit"
                         v-if="canEdit">
                保存
              </el-button>
              <el-button type="default" :disabled="true" v-else>
                保存(无权限)
              </el-button>
            </div>

          </el-col>
        </el-row>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {mapGetters} from "vuex";

export default {
  name: 'ScheduleDetail',
  inheritAttrs: false,
  components: {},
  props: {
    searchCondition: {
      type: Object,
      default() {
        return {
          year: null,
          month: null,
          day: null,
          artist_id: null,
          group_id: null,
          nickname: null,
          plan_date: null,
          platform: {name: null, code: null},
        }
      }
    },
    // canEdit: {
    //   type: Boolean,
    //   default() {
    //     return false;
    //   }
    // }
  },
  data() {
    return {
      canEdit: false,
      loadingCommit: false,
      formData: {
        plan_content: '',
        source_id: null,//来源ID,一般商务管理同步来的档期才有（不可修改商务内容）
        cell_style: '',
        plan_type: 'custom',
      },
      planTypeMap: [
        {label: '日常内容', color_val: 'rgba(82, 252, 159, 0.2)', plan_type: 'daily', disabled: false},
        {label: '挂车商务', color_val: 'rgba(255, 190, 190, 0.9)', plan_type: 'cart', disabled: true},
        {label: '品宣商务', color_val: 'rgba(96,168,239,0.3)', plan_type: 'promotion', disabled: true},
        {label: '线下活动', color_val: 'rgba(82,238,252,0.2)', plan_type: 'offline', disabled: false},
        {label: '直播', color_val: 'rgba(255,200,10,0.4)', plan_type: 'live', disabled: false},
        {label: '平台分发', color_val: 'rgba(255, 140, 0, 0.15)', plan_type: 'distribute', disabled: false},
        {label: '其他', color_val: '#ffffff', plan_type: 'custom', disabled: false},
      ],
      rules: {
        plan_content: [{
          required: true,
          message: '请输入档期内容',
          trigger: 'blur'
        }],
      },
      color: 'rgba(255, 69, 0, 0.68)',
      predefineColors: [
        '#ff4500',
        '#ff8c00',
        '#ffd700',
        '#90ee90',
        '#00ced1',
        '#1e90ff',
        '#c71585',
        'rgba(255, 69, 0, 0.68)',
        'rgb(255, 120, 0)',
        'hsv(51, 100, 98)',
        'hsva(120, 40, 94, 0.5)',
        'hsl(181, 100%, 37%)',
        'hsla(209, 100%, 56%, 0.73)',
        '#c7158577'
      ],
      innerVisible: false,
      delayFormData: {new_date: null, form_id: null},
      currentId: null,
    }
  },
  computed: {
    ...mapGetters(['userPermissions', 'userDptInfo']),
    dialogTitle() {
      return `${this.searchCondition.year}年${this.searchCondition.month}月${this.searchCondition.day}日 / ${this.searchCondition.platform ? this.searchCondition.platform.name : ''} / ${this.searchCondition.nickname}`
    }
  },
  // watch: {
  //   color(val) {
  //     this.saveCell(val)
  //   }
  // },
  created() {
  },
  mounted() {
  },
  methods: {
    onOpen() {
      this.getAInfo()
      this.canEdit = this.calcPermission()
    },
    onClose() {
      this.$refs['elForm'].resetFields()
      this.$emit('refreshData')
    },
    close() {
      this.$emit('update:visible', false)
    },
    handleConfirm() {
      this.$refs['elForm'].validate(valid => {
        if (!valid) return
        this.loadingCommit = true
        this.saveInfo()
      })
    },
    async saveInfo() {
      let data = {
        year: this.searchCondition.year,
        month: this.searchCondition.month,
        day: this.searchCondition.day,
        plan_date: this.searchCondition.plan_date,
        artist_id: this.searchCondition.artist_id,
        nickname: this.searchCondition.nickname,
        platform_code: this.searchCondition.platform.code,
        platform_name: this.searchCondition.platform.name,
        plan_content: this.formData.plan_content,
        plan_type: this.formData.plan_type,
        cell_style: `background-color: ${this.color}`
      }
      await this.$api.saveArtistSchedule(data)
      this.loadingCommit = false
      this.close()
    },
    async getAInfo() {
      let {info} = await this.$api.getASchedule(this.searchCondition.artist_id, this.searchCondition.plan_date, this.searchCondition.platform.code)
      this.currentId = info.id//档期的IDs
      this.formData.plan_content = info.plan_content
      this.formData.source_id = info.source_id || null
      let cell_style = info.cell_style
      this.formData.cell_style = cell_style
      this.formData.plan_type = info.plan_type ? info.plan_type : 'custom'

      this.color = 'rgba(255, 255, 255, 1)'// 设置默认
      if (cell_style && cell_style.indexOf('background-color') > -1) {
        //
        let arr = cell_style.split(':')
        if (arr[1])
          this.color = arr[1].trim() // 同步为当前

      }
    },
    async saveCell(val) {
      // this.formData.cell_style = `background-color: ${ val }`

      let data = {
        year: this.searchCondition.year,
        month: this.searchCondition.month,
        day: this.searchCondition.day,
        plan_date: this.searchCondition.plan_date,
        artist_id: this.searchCondition.artist_id,
        nickname: this.searchCondition.nickname,
        platform_code: this.searchCondition.platform.code,
        platform_name: this.searchCondition.platform.name,
        cell_style: `background-color: ${val}`
      }

      await this.$api.saveArtistScheduleCellStyle(data)
    },
    changePlanType(item) {
      this.color = item.color_val
    },
    delay() {
      this.innerVisible = true
    },
    async saveDelay() {
      this.delayFormData.form_id = this.currentId
      // console.log('delayFormData 表单：', this.delayFormData)

      await this.$api.delayInfoArtistSchedule(this.delayFormData)
      this.innerVisible = false
      this.close()
    },
    delBtn() {
      this.$confirm('此操作将永久删除该排期, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.delSchedule()
        // this.$message({
        //   type: 'success',
        //   message: '删除成功!'
        // });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });

    },
    async delSchedule() {
      await this.$api.delArtistSchedule(this.currentId)
      this.innerVisible = false
      this.close()
    },
    //计算权限
    calcPermission() {
      //录入权限
      let hasPermission = this.userPermissions.indexOf('schedule_create') > -1
      //范围权限
      let rangePermission = false

      let currentGroupId = this.searchCondition.group_id
      //数据范围
      //有全公司 数据权限
      if (this.userPermissions.indexOf('schedule_data_company') > -1) {
        rangePermission = true

      } else if (this.userPermissions.indexOf('schedule_data_dpt') > -1) {
        // 当前选中的组在本部及其组，则有权限修改
        let dptRange = this.userDptInfo.dpt_range
        rangePermission = dptRange ? dptRange.indexOf(currentGroupId) > -1 : false

      } else if (this.userPermissions.indexOf('schedule_data_group') > -1) {
        // 当前选中的组在个人归属的组（一个人可在多组），则有权限修改
        let dpt_ids = this.userDptInfo.my_dpt_range ? this.userDptInfo.my_dpt_range : []
        rangePermission = dpt_ids ? dpt_ids.indexOf(currentGroupId) > -1 : false

      } else {
        rangePermission = false
      }

      // console.log(`组：${ this.searchCondition.group_id }, hasPermission:${ hasPermission }`)
      return hasPermission && rangePermission
    },
  }
}

</script>
<style>
</style>
