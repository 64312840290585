<template>
  <div>
    <!--    日程-->
    <el-row>
      <el-col :span="24">
        <div class="el-calendar">
          <div class="el-calendar__header">
            <div class="el-calendar__button-group">
              <div class="el-button-group">
                <button type="button" class="el-button el-button--plain el-button--mini" @click="prevMonth">
                  <i class="el-icon-d-arrow-left"></i><span></span></button>
              </div>
            </div>
            <div class="el-calendar__title">
              {{ searchCondition.year }} 年 {{ searchCondition.month }} 月【{{ searchCondition.nickname }}】档期
            </div>
            <div class="el-calendar__button-group">
              <div class="el-button-group">
                <button type="button" class="el-button el-button--plain el-button--mini" @click="nextMonth">
                  <span></span><i class="el-icon-d-arrow-right"></i></button>
              </div>
            </div>
          </div>
          <el-row>
            <el-col :span="24">
              <div class="el-calendar__body">
                <table cellspacing="0" cellpadding="0" class="el-calendar-table">
                  <thead>
                  <th>
                    <el-link @click="openPlatformOrder" :title="`点击，进行平台排序设置`" v-if="canEdit"
                             class="platform-title">平台
                      <i class="el-icon-edit"></i></el-link>
                    <span v-else>平台</span>
                  </th>
                  <th>一</th>
                  <th>二</th>
                  <th>三</th>
                  <th>四</th>
                  <th>五</th>
                  <th>六</th>
                  <th>日</th>
                  </thead>
                  <tbody>
                  <tr class="el-calendar-table__row" v-for="(row,k)  in tableData" :key="k" @dragover="allowDrop">
                    <td :class="`platform ${row.show_date?'week':''}`" @contextmenu="openMenu(row,$event)">
                      <span :class="`${row.hidden_row?'hidden-row':''}`">{{ row.platform.name }}</span>
                    </td>
                    <td :class="`${item.class_name} ${row.show_date?'week':''}`"
                        v-for="(item,j)  in row.weekDays"
                        :key="j"
                        :style="`${extractField(row,item,'cell_style')}`">
                      <div class="" v-if="row.show_date">
                        <span v-if="item.fullDate" :class="`dates ${todayDate===item.fullDate?'today':''}`">{{
                            $utils.parseTime(item.fullDate, '{y}/{m}/{d}')
                          }}
                        </span>
                        <span v-else class="dates">{{ item.num }}</span>
                      </div>
                      <div class="el-calendar-day" v-else draggable="true" @dragstart="dragstart(row,item)"
                           @drop="dragend(item,j,k)">
                        <div v-if="item.fullDate" @contextmenu="openPlanMenu(row,item,$event)">
                          <div class="plan_div">
                            <div class="plan_type" @click="showSteps(extractField(row, item, 'source_id'))">
                              <span class="plan_type_alias">{{ extractField(row, item, 'plan_type_alias') }}</span>
                              <span class="rate_progress" v-if="!!extractField(row, item, 'source_id')">
                                {{ extractField(row, item, 'rate_progress') }}</span>
                            </div>
                            <div class="tag-finished">
                              <i class="el-icon-finished" v-if="extractField(row, item, 'status')==='Y'"></i>
                            </div>
                          </div>
                          <div class="plan_content" @click="showDetail(item,row.platform)">
                            {{ extractField(row, item, 'plan_content') }}
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </el-col>
          </el-row>
        </div>
      </el-col>
    </el-row>
    <!--    日程详情-->
    <schedule-detail :visible.sync="dialogVisible" :search-condition="formData" @refreshData="loadDataMap"/>
    <!--    平台排序-->
    <PlatformOrder :visible.sync="dialogOrderVisible"
        :search-condition="{artist_id:searchCondition.artist_id,nickname:searchCondition.nickname}"
        @refreshData="loadDataMap" :can-edit="canEdit" />

    <!--    右键菜单-->
    <Contextmenu :menu-data="menuData" @change="changeCheck" />
    <!--    日程排期标记为“完成”-->
    <TagContextmenu :menu-data="menuPlanData" @change="tagDone" />

    <!--    执行进度-->
    <RateProgress :visible.sync="dialogExecVisible" :edit-obj="{id:currentPlanId}" />
  </div>
</template>

<script>
import EditableCell from '@/components/EditableCell'
import ScheduleDetail from './ScheduleDetail'
import Contextmenu from './Contextmenu'
import TagContextmenu from './TagContextmenu'
import PlatformOrder from './PlatformOrder'
import RateProgress from './RateProgress'
import { mapGetters } from 'vuex'

export default {
  name: "ScheduleTable",
  components: {
    EditableCell, ScheduleDetail, Contextmenu, TagContextmenu, PlatformOrder, RateProgress
  },
  props: {
    searchCondition: {
      type: Object,
      default() {
        return {
          showHidden: false,
          ym: '',
          year: null,
          month: null,
          nickname: null,
          artist_id: null,
          platform: {name: null, code: null},
          platformMap: {}
        }
      }
    }
  },
  data() {
    return {
      todayDate: null,
      days: [],
      platforms: [
        // { code: 'douyin', name: '抖音' },
        // { code: 'ks', name: '快手' }
      ],
      dialogVisible: false,//编辑模态框
      dialogOrderVisible: false,//排序模态框
      canEdit: false,//有权限编辑
      formData: {
        year: null,
        month: null,
        day: null,
        plan_date: null,
        artist_id: null,
        group_id: null,
        nickname: null,
        platform: {}
      },
      dataMap: {},
      hiddenRows: [],
      //start:鼠标右键菜单
      menuData: {
        visible: false,
        top: 0,
        left: 0,
      },
      currentRow: {},//当前选中的行
      currentItem: {},//当前选中的日期信息
      showHidden: false,//显示已隐藏的,
      //排期内容右键菜单
      menuPlanData: {
        visible: false,
        top: 0,
        left: 0,
      },
      doneColor: '#E4E7ED',//完成的色值
      planTypeMap: [
        { label: '日常内容', color_val: 'rgba(82, 252, 159, 0.2)', plan_type: 'daily' },
        { label: '挂车商务', color_val: 'rgba(255, 190, 190, 0.9)', plan_type: 'cart' },
        { label: '品宣商务', color_val: 'rgba(96,168,239,0.3)', plan_type: 'promotion' },
        { label: '线下活动', color_val: 'rgba(82,238,252,0.2)', plan_type: 'offline' },
        { label: '直播', color_val: 'rgba(255,200,10,0.4)', plan_type: 'live' },
        { label: '平台分发', color_val: 'rgba(255, 140, 0, 0.15)', plan_type: 'distribute' },
        { label: '其他', color_val: '#FFFFFF', plan_type: 'custom' }
      ],
      dialogExecVisible: false,
      currentPlanId: null,
      dragendWeek: {},
      dragendTime: '',
      editData: {},
      delayFormData: {}
    }
  },
  watch: {
    'searchCondition.showHidden'( val, oldVal ) {
      if (val !== oldVal) {
        this.showHidden = val//显示已隐藏的
      }
    },
    'searchCondition.artist_id'( val, oldVal ) {
      if (val !== oldVal) {
        // console.log('change artist')
        this.showList()
      }
    },
    'searchCondition.year'( val, oldVal ) {
      if (val !== oldVal) {
        // console.log('change year')
        this.showList()
      }
    },
    'searchCondition.month'( val, oldVal ) {
      if (val !== oldVal) {
        // console.log('change month')
        this.showList()
      }
    },
    // searchCondition: {
    //   deep: true,
    //   // eslint-disable-next-line
    //   handler(val) {
    //     this.showHidden = val.showHidden
    //     // this.hiddenRows = []
    //     this.showList()
    //   }
    // }
  },
  computed: {
    ...mapGetters(['userPermissions', 'userDptInfo']),
    tableData() {
      // eslint-disable-next-line
      let platforms = this.platforms;//红人拥有的平台（已排序）

      let dataList = [];
      let daysArr = this.$utils.array_chunk(this.days, 7)
      for (let j in daysArr) {
        // eslint-disable-next-line
        let weekDays = daysArr[j]
        let h = 0;
        dataList.push({hidden_row: false, show_date: true, platform: {}, weekDays: weekDays, week: j})

        for (let i in platforms) {
          h++;
          // eslint-disable-next-line
          let platform = platforms[i]
          let row = {hidden_row: false, show_date: h === 0, platform: platform, weekDays: weekDays, week: j}
          let k = `${row.platform.code}_${row.week}`
          if (this.filterRange.indexOf(k) === -1) {
            dataList.push(row)
          } else {
            if (this.showHidden) {
              row['hidden_row'] = true
              dataList.push(row)
            }
          }
        }
      }
      return dataList
    },
    filterRange() {
      let rangeData = []

      this.hiddenRows.forEach(( item ) => {
        rangeData.push(`${item.platform_code}_${item.week}`)
      })
      return rangeData
    }
  },
  methods: {
    allowDrop(ev) {
      ev.preventDefault()
    },
    async dragend(data) {
      this.delayFormData.new_date = data.fullDate
      if (this.delayFormData.new_date) {
        if (this.delayFormData.form_id) {
          let id = await this.$api.delayInfoArtistSchedule(this.delayFormData)
          if (id) await this.loadDataMap()
        } else {
          this.$message({
            type: 'info',
            message: '数据错误，请刷新重试'
          })
        }
      } else {
        this.$message({
          type: 'info',
          message: '日期错误，请选择正确日期'
        })
      }


    },
    async dragstart(data, item) {
      let { info } = await this.$api.getASchedule(this.searchCondition.artist_id, item.fullDate, data.platform.code)
      this.delayFormData.form_id = info.id
    },
    //https://www.cnblogs.com/wjs0509/p/11686859.html
    getDates(d, t = 0) {
      const curDate = new Date(d.replace(/-/g, '/'))
      const curMonth = curDate.getMonth()
      curDate.setMonth(curMonth + t + 1)
      curDate.setDate(0)
      return new Array(curDate.getDate()).fill(0).map((item, key) => {
        return key + 1
      })
    },
    getWeek(d) {
      let curDate = new Date(d.replace(/-/g, '/'))
      curDate.setDate(1)
      return curDate.getDay()
    },
    getFullChunkDates( d ) {
      let curDates = this.getDates(d);
      let preDates = this.getDates(d, -1);
      let nexDates = this.getDates(d, 1);
      const curWeek = this.getWeek(d);
      // eslint-disable-next-line
      curDates = curDates.map(( i, k ) => {
        return {
          num: i,
          fullDate: /(^\d{4})-(\d{1,2})-/.exec(d)[0] + i,
          show: 1,
          class_name: 'current'
        }
      })
      preDates = preDates.map(i => {
        return {
          num: i,
          show: 0,
          class_name: 'prev'
        }
      })
      nexDates = nexDates.map(i => {
        return {
          num: i,
          show: 0,
          class_name: 'next'
        }
      })
      const preCurDates = preDates.slice(preDates.length - (curWeek === 0 ? 6 : curWeek - 1), preDates.length).concat(curDates);
      return preCurDates.concat(nexDates.slice(0, 42 - preCurDates.length))
    },
    getLastDays( days ) {
      //几天前的时间
      let day1 = new Date();
      day1.setTime(day1.getTime() - 24 * 60 * 60 * 1000 * days);
      return day1.getFullYear() + "-" + (day1.getMonth() + 1) + "-" + day1.getDate();
    },
    showDetail( item, platform ) {
      if (item.fullDate) {
        if (!this.searchCondition.artist_id) {
          this.$notify.warning('请先选择红人！！！')
          return
        }
        let ymdArr = item.fullDate.split('-')
        this.formData = {
          year: ymdArr[0] * 1,
          month: ymdArr[1] * 1,
          day: ymdArr[2] * 1,
          plan_date: item.fullDate,
          artist_id: this.searchCondition.artist_id,
          group_id: this.searchCondition.group_id,
          nickname: this.searchCondition.nickname,
          platform: platform
        }

        //权限判断
        this.canEdit = this.calcPermission()

        this.dialogVisible = true
      }

    },
    showSteps( source_id ) {
      //查看进度
      if (source_id) {
        this.currentPlanId = source_id
        this.dialogExecVisible = true
      }

    },
    async showList() {
      if (this.searchCondition.artist_id && this.searchCondition.year && this.searchCondition.month) {
        await this.initHideRows()
        await this.loadDataMap()
        //获取红人平台
        this.days = this.getFullChunkDates(`${this.searchCondition.year}-${this.searchCondition.month}-01`)

        this.canEdit = this.calcPermission()
      }
    },
    async loadDataMap() {
      this.dataMap = {}
      if (this.searchCondition.artist_id && this.searchCondition.year && this.searchCondition.month) {
        let {list, platforms} = await this.$api.getArtistScheduleDataMap({
          year: this.searchCondition.year,
          month: this.searchCondition.month,
          artist_id: this.searchCondition.artist_id
        })
        this.dataMap = list
        this.platforms = platforms
      }
    },

    showToday() {
      let todayDate = this.getLastDays(0)
      let ymdArr = todayDate.split('-')
      this.searchCondition.year = ymdArr[0]
      this.searchCondition.month = ymdArr[1]
      this.searchCondition.ym = `${ymdArr[0]}-${ymdArr[1]}`
      this.showList()
    },
    changeMonth( type ) {
      let year = this.searchCondition.year
      let month = this.searchCondition.month

      const start = new Date(year, month - 1, 1);
      if (type === 'prev') {
        start.setMonth(start.getMonth() - 1);
      } else {
        start.setMonth(start.getMonth() + 1);
      }
      this.searchCondition.year = start.getFullYear()
      this.searchCondition.month = start.getMonth() + 1
      this.searchCondition.ym = this.searchCondition.year + '/' + this.searchCondition.month
    },
    prevMonth() {
      this.changeMonth('prev')
    },
    nextMonth() {
      this.changeMonth('next')
    },
    planTypeAlias( plan_type ) {
      if (plan_type === 'custom') {
        return ''
      }
      let planType = this.planTypeMap.find(( value ) => value.plan_type === plan_type)
      return planType ? planType.label : '';
    },
    extractField( row, item, field ) {
      let dataMap = this.dataMap ? this.dataMap : {}
      let key = `${row.platform.code}_${item.fullDate}`
      if (field === 'cell_style') {
        //已完成的色值：#E4E7ED
        let status = dataMap[key] ? dataMap[key]['status'] : 'N'
        if (status === 'Y') {
          return `background-color: ${this.doneColor}`
        }
      }
      return dataMap[key] ? dataMap[key][field] : ''
    },
    openMenu( row, e ) {
      // console.log(row)
      if (row.platform.code && this.userPermissions.indexOf('schedule_setting') !== -1) {
        this.currentRow = row
        e.preventDefault();//阻止元素发生默认的行为
        const offsetLeft = this.$el.getBoundingClientRect().left; // container margin left
        const offsetTop = this.$el.getBoundingClientRect().top; // container margin left
        this.menuData = {
          visible: true,
          left: e.clientX - offsetLeft,
          top: e.clientY - offsetTop
        }
        document.addEventListener('click', this.closeMenu) // 给整个document新增监听鼠标事件，点击任何位置执行foo方法
      }
    },
    // eslint-disable-next-line
    changeCheck( val ) {
      let v = {
        artist_id: this.searchCondition.artist_id,
        year: this.searchCondition.year,
        month: this.searchCondition.month,
        platform_code: this.currentRow.platform.code,
        week: this.currentRow.week
      }
      let index = this.filterRange.indexOf(`${v["platform_code"]}_${v["week"]}`)

      if (val === 'N') {
        if (index === -1) {
          this.hiddenRows.push(v)
          //记录配置-》后端
          this.$api.AddArtistScheduleHideRow(v)
        }
      } else {
        if (index > -1) {
          this.hiddenRows.splice(index, 1)
          // 删除配置-》后端
          this.$api.delArtistScheduleHideRow(v)
        }
      }
    },
    openPlanMenu( row, item, e ) {
      if (this.calcPermission()) {
        this.currentRow = row
        this.currentItem = item
        if (this.currentItem.fullDate) {
          e.preventDefault();//阻止元素发生默认的行为
          const offsetLeft = this.$el.getBoundingClientRect().left; // container margin left
          const offsetTop = this.$el.getBoundingClientRect().top; // container margin left
          this.menuPlanData = {
            visible: true,
            left: e.clientX - offsetLeft,
            top: e.clientY - offsetTop
          }
          document.addEventListener('click', this.closeMenu) // 给整个document新增监听鼠标事件，点击任何位置执行foo方法
        }
      }
    },
    async initHideRows() {
      //初始化-隐藏行数据
      let {list} = await this.$api.getArtistScheduleHideRows({
        artist_id: this.searchCondition.artist_id,
        year: this.searchCondition.year,
        month: this.searchCondition.month,
      })
      this.hiddenRows = list
    },
    //计算权限
    calcPermission() {
      //录入权限
      let hasPermission = this.userPermissions.indexOf('schedule_create') > -1
      //范围权限
      let rangePermission = false

      let currentGroupId = this.searchCondition.group_id
      //数据范围
      //有全公司 数据权限
      if (this.userPermissions.indexOf('schedule_data_company') > -1) {
        rangePermission = true

      } else if (this.userPermissions.indexOf('schedule_data_dpt') > -1) {
        // 当前选中的组在本部及其组，则有权限修改
        let dptRange = this.userDptInfo.dpt_range
        rangePermission = dptRange ? dptRange.indexOf(currentGroupId) > -1 : false

      } else if (this.userPermissions.indexOf('schedule_data_group') > -1) {
        // 当前选中的组在个人归属的组（一个人可在多组），则有权限修改
        let dpt_ids = this.userDptInfo.my_dpt_range ? this.userDptInfo.my_dpt_range : []
        rangePermission = dpt_ids ? dpt_ids.indexOf(currentGroupId) > -1 : false

      } else {
        rangePermission = false
      }

      // console.log(`组：${ this.searchCondition.group_id }, hasPermission:${ hasPermission }`)
      return hasPermission && rangePermission
    },
    //标记完成
    async tagDone( val ) {
      if (this.currentItem.fullDate && val) {
        let data = {
          artist_id: this.searchCondition.artist_id,
          nickname: this.searchCondition.nickname,
          year: this.searchCondition.year,
          month: this.searchCondition.month,
          platform_code: this.currentRow.platform.code,
          plan_date: this.currentItem.fullDate,
          status: val
        }
        await this.$api.tagStatusArtistSchedule(data)
        await this.loadDataMap()
      }
    },
    //右键菜单：设置是否月考核
    closeMenu( e ) {
      //未点击在 菜单上，则关闭
      if (e.target.className.indexOf('contextmenu__item') === -1) {
        this.menuData.visible = false;
        this.menuPlanData.visible = false;
        document.removeEventListener('click', this.closeMenu)
      }
    },
    //平台排序设置
    openPlatformOrder() {
      // console.log('e', e)
      //权限判断
      this.canEdit = this.calcPermission()
      if (this.canEdit)
        this.dialogOrderVisible = true

    },
  },
  created() {
    this.todayDate = this.getLastDays(0)
  }
}
</script>

<style scoped>
.c-day {
  display: flex;
  width: 700px;
  flex-wrap: wrap;
  justify-content: space-around;
}

.d-item {
  display: flex;
  display: inline-block;
  width: 100px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  text-align: center;
  padding: 40px;
}

.platform {
  text-align: center;
  line-height: 100%;
  vertical-align: middle;
  cursor: pointer;
}

.current, .prev, .next {
  text-align: center;
}

.plan_div {
  display: flex;
  /*min-height: 10px;*/
  /*border-bottom: 1px solid #EBEEF5;*/
}

.plan_type {
  flex: 9;
  font-weight: 600;
}

/**
日程内容显示三行
 */
.plan_content {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  padding-top: 5px;
  min-width: 100%;
  min-height: 50px;
  /*background-color: #EBEEF5;*/
  /*border: red 1px solid;*/
  /*z-index: 999;*/
}

.tag-finished {
  z-index: 1000;
  top: 1px;
  right: 1px;
  flex: 1;
}

.dates {
  vertical-align: middle;
  /*font-weight: 600;*/
  line-height: 30px;
  height: 30px;
}

.week {
  /*border-top: 1px solid #DCDFE6;*/
  /*border-bottom: 1px solid #434549;*/
  margin-top: 10px;
  /*background-color: rgba(223, 224, 226, 0.7);*/
  /*background-color: rgba(220, 223, 230, 0.7);*/
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
}

.today {
  /*border-radius: 50%;*/
  display: inline-block;
  /*font-size: 20px;*/
  line-height: 30px;
  /*width: 30px;*/
  /*height: 30px;*/
  border: #ff3176 1px dashed;
  padding: 1px 5px;
  /*background-color: #ff3176;*/
  /*margin-top: 2px;*/
  /*margin-bottom: 2px;*/
}

/**
隐藏的行：平台名加划线
 */
.hidden-row {
  text-decoration: line-through;
}

.platform-title {

}

.rate_progress {
  font-size: 10px;
  font-weight: lighter;
  font-style: initial;
}

.rate_progress::before {
  content: '(';
}

.rate_progress::after {
  content: ')';
}
</style>
